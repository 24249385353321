import React, {Component} from 'react';
import Form from '../components/form';
import {navigate} from 'gatsby';

class ModelsPage extends Component {

  closeModalHandle(e) {
    e.preventDefault();
    navigate('/');
  }

  render() {
    return (
      <>
        <section className="models">
          <div className="models__wrapper">
            <Form/>

            <div className="models-wrapper__close">
              <button onClick={this.closeModalHandle.bind(this)}>Back to PP Barbers</button>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ModelsPage;
