import React, {Component} from 'react';
import Logo from '../assets/images/pphh-logo-brown.svg';
import {navigate, Link} from 'gatsby';

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (e.target.name === 'condition_yes') {
      this.refs.no.checked = false;
      this.setState({
        'condition_yes': 'on',
        'condition_no': 'off',
      });
    } else if (e.target.name === 'condition_no') {
      this.refs.yes.checked = false;
      this.setState({
        'condition_yes': 'off',
        'condition_no': 'on',
      });
    } else {
      this.setState({[e.target.name]: e.target.value});
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': this.refs.form.getAttribute('name'),
        ...this.state,
      }),
    }).then(() => {
      this.setState({result: 'success'});
    }).catch(error => this.setState({result: 'fail'}));

    navigate('/thank-you');
  }

  render() {
    let props = {
      ref: 'form',
      name: 'models',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    };

    return (
      <>
        <form {...props} onSubmit={this.handleSubmit} className={`form form-modal ${this.props.className}`}>
          <div className="form-wrapper">
            <Link to="/">
              <img className="img-fluid" src={Logo} alt="PPHH Barbers"/>
            </Link>

            <h2>Tell us about yourself</h2>

            <div className="form-wrapper__input">
              <input onChange={this.handleChange} name="name" placeholder="Your name" type="text" required/>
              <input onChange={this.handleChange} name="email" placeholder="Your email" type="email" required/>
              <input onChange={this.handleChange} name="phone" placeholder="Contact number" type="phone" required/>
            </div>

            <p>What type of hair do you have? (select all that apply)</p>

            <div className="form-wrapper__checkbox">
              <div className="form-wrapper__checkbox--group">
                <div className="main">
                  <input onChange={this.handleChange} name="curly" id="curly" type="checkbox"/>
                  <label htmlFor="curly">Curly</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="long" id="long" type="checkbox"/>
                  <label htmlFor="long">Long</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="grey" id="grey" type="checkbox"/>
                  <label htmlFor="grey">Grey</label>
                </div>
              </div>

              <div className="form-wrapper__checkbox--group">

                <div className="main">
                  <input onChange={this.handleChange} name="beard" id="beard" type="checkbox"/>
                  <label htmlFor="beard">Beard</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="stubble" id="stubble" type="checkbox"/>
                  <label htmlFor="stubble">Stubble</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="moustache" id="moustache" type="checkbox"/>
                  <label htmlFor="moustache">Moustache</label>
                </div>
              </div>

              <div className="form-wrapper__checkbox--group">
                <div className="main">
                  <input onChange={this.handleChange} name="medium-length" id="medium-length" type="checkbox"/>
                  <label htmlFor="medium-length">Medium-length</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="precision-fade" id="precision-fade" type="checkbox"/>
                  <label htmlFor="precision-fade">Precision fade</label>
                </div>

                <div className="main">
                  <input onChange={this.handleChange} name="other" id="other" type="checkbox"/>
                  <label htmlFor="other">Other</label>
                </div>
              </div>
            </div>

            <p>Are you available to come in on Tuesday after 6pm?</p>

            <div className="form-wrapper__checkbox form--last">
              <div className="main">
                <input onChange={this.handleChange} ref="yes" name="condition_yes" id="yes" type="checkbox"/>
                <label htmlFor="yes">Yes</label>
              </div>

              <div className="main">
                <input onChange={this.handleChange} ref="no" name="condition_no" id="no" type="checkbox"/>
                <label htmlFor="no">No</label>
              </div>
            </div>

            <button className="btn" type="submit">Submit</button>
          </div>
        </form>
      </>
    );
  }
}

export default Form;
